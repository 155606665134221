import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Packages } from '../components/composites/packages';

import { Hero, ImageClipping } from '../components/containers/hero';
import { Panel } from '../components/containers/panel';
import { Section, Style as SectionStyle } from '../components/containers/section';
import { SectionText } from '../components/containers/section-text';
import { Style as ButtonStyle, Type as ButtonType } from '../components/elements/button';
import { TextButton } from '../components/elements/text-button';
import { Site } from '../components/shells/site';
import { Heading, Level as HeadingLevel, Level } from '../components/typography/heading';
import { Seo } from '../components/utilities/seo';

export default (): React.ReactElement => {
    return (
        <Site>
            <Seo
                title={'Trainingen in regio Maastricht'}
            />

            <Hero
                image={<StaticImage src={'../../assets/images/hero-training-1.jpg'} alt={''} />}
                imageClipping={ImageClipping.VerticalBlob}
                pageTitle={'Trainingen in regio Maastricht'}
                pageText={'Wil je in groepsverband sporten? Meld je dan aan voor mijn trainingen!'}
            />

            <Section style={SectionStyle.Light}>
                <SectionText>
                    <Heading level={Level.Text}>
                        Slow running
                    </Heading>
                    <div className={'grid md:grid-cols-2 gap-12 mt-12'}>
                        <div className={'text-left'}>
                            <h2 className={'text-2xl font-bold mb-4'}>Wat is slow running?</h2>
                            <div className={'prose'}>
                                <p>De naam zegt het al, bij slow running ligt het tempo een stuk lager dan bij een “normaal” rondje hardlopen. Het tempo ligt lager, maar dit komt vooral omdat de hartslag niet boven de 75% komt.</p>
                                <p>Het is een tempo waarbij het mogelijk is om nog een praatje met je medelopers te maken, zonder dat je gelijk buiten adem bent.</p>
                            </div>

                            <Panel className={'mt-8'}>
                                <h2 className={'text-2xl font-bold mb-4'}>Doe mee!</h2>
                                <p><strong>Tijd:</strong> zaterdag 10:00 - 11:00<br /><strong>Locatie:</strong> Bergrust Sint Pieter</p>

                                <h3 className={'font-bold mt-8'}>Kosten</h3>
                                <p>Strippenkaart 10 lessen: 80 euro. Wil je een gratis proefles? Dat kan natuurlijk!</p>

                                <TextButton
                                    type={ButtonType.Link}
                                    style={ButtonStyle.Primary}
                                    url={`https://wa.me/31641777848?text=${encodeURIComponent(
                                        'Hoi! Ik wil me graag aanmelden voor slow running op zaterdag om 8:30'
                                    )}`}
                                    caption={'Aanmelden via WhatsApp'}
                                    callout={false}
                                    className={'mt-8'}
                                />
                            </Panel>
                        </div>
                        <div className={'text-left'}>
                            <h2 className={'text-xl font-bold mb-1'}>Waarom slow running?</h2>
                            <div className={'prose'}>
                                <ul>
                                    <li>Veilige manier om conditie en duurvermogen te verbeteren.</li>
                                    <li>Belastbaarheid verhogen.</li>
                                    <li>Looptechniek verbeteren.</li>
                                    <li>Sociale contacten bij het lopen in groepsverband.</li>
                                </ul>
                            </div>
                            <h2 className={'text-xl font-bold mt-8 mb-1'}>Voor wie is slow running?</h2>
                            <div className={'prose'}>
                                <p className={'mb-0'}>Voor iedereen!</p>
                                <ul className={'mt-0'}>
                                    <li>Voor de beginnende hardloper.</li>
                                    <li>Voor diegene die het hardlopen op een veilige manier weer op wil pakken.</li>
                                    <li>Voor diegene die dat “duwtje in de rug” nodig heeft.</li>
                                    <li>Voor de ervaren loper die onder begeleiding of in groep zijn Z2 training wil doen.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </SectionText>
            </Section>

            {/*
            <Section style={SectionStyle.Light}>
                <SectionText>
                    <Heading level={Level.Text}>
                        Bootcamp-les 40+
                    </Heading>
                    <div className={'grid md:grid-cols-2 gap-12 mt-12'}>
                        <div className={'text-left'}>
                            <div className={'prose'}>
                                <p>Bij deze les is de intensiteit lager dan bij een ‘normale’ bootcamp. Tussendoor rennen we in een rustig tempo kleine stukjes.</p>
                                <p>We gaan er met z’n allen vol voor maar een tandje rustiger. Zo wordt er minder gesprongen en zijn de oefeningen niet explosief. Iedereen traint op zijn eigen niveau. </p>
                                <p>Bij de bootcamp werken we veel met circuitjes waarbij mensen alleen een oefening uitvoeren, of in kleine groepjes. En alle oefeningen kunnen aangepast worden naar het niveau dat iemand aan kan. Ik stimuleer mensen om hun grenzen op te zoeken zonder dat ze het door hebben, door het juiste niveau te zoeken van iemand en van daaruit iemand sterker en fitter te maken.</p>
                                <p>Voorbeelden van oefeningen die veel gedaan worden zijn squats, lunges, push-ups, hardlopen, huppelen, touwtje springen, planken, burpees. We gebruiken hierbij soms gewichtjes of ons eigen lichaamsgewicht en we maken gebruik van bankjes, hekjes, etc.</p>
                            </div>
                        </div>
                        <div className={'text-left'}>
                            <Panel>
                                <h2 className={'text-2xl font-bold mb-4'}>Doe mee!</h2>
                                <p><strong>Tijd:</strong> donderdag 19:15 - 20:15<br /><strong>Locatie:</strong> Cadier en Keer</p>

                                <h3 className={'font-bold mt-8'}>Kosten</h3>
                                <p>Strippenkaart 10 lessen: 80 euro. Wil je een gratis proefles? Dat kan natuurlijk!</p>

                                <TextButton
                                    type={ButtonType.Link}
                                    style={ButtonStyle.Primary}
                                    url={`https://wa.me/31641777848?text=${encodeURIComponent(
                                        'Hoi! Ik wil me graag aanmelden voor de 40+ bootcamp-les op donderdag om 19:15'
                                    )}`}
                                    caption={'Aanmelden via WhatsApp'}
                                    callout={false}
                                    className={'mt-8'}
                                />
                            </Panel>
                        </div>
                    </div>
                </SectionText>
            </Section>
            */}

            <Section>
                <SectionText>
                    <Heading level={HeadingLevel.Second}>Pakketten</Heading>
                    <p>Wil je alles in één keer aanpakken? Kies dan voor een van mijn pakketten. Hiermee krijg je een combinatie van <a href={'/leefstijlcoaching'} className={'underline'}>leefstijlcoaching</a>, trainingen en <a href={'/powerwalking'} className={'underline'}>powerwalking</a> voor een pakketprijs. Wel zo makkelijk!</p>
                </SectionText>
                <Packages className={'mt-12'} />
            </Section>
        </Site>
    );
};
